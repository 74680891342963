import React from 'react'
import { useNavigate } from 'react-router-dom'
export default function GetInTouch() {


    let navigate=useNavigate()
    return (
        <>

            <div className='flex flex-wrap-reverse  container mx-auto overfh min-h-[80vh] md:mt-10 md:my-10 my-1   justify-center '>
                <div className='flex flex-col justify-center items-start md:w-1/2 text-center '>
                    <p className='text-4xl mb-8 w-full text-center md:text-start my-5'>Get In  Touch</p>
                    <span className='font-bold w-full text-center md:text-start text-[#758694] text-xl'>Main Office</span>
                    <span className='font-bold w-full text-center md:text-start text-[#758694] text-xl'>18 Cleopatra Street, Apartment 18, 8th Floor</span>
                    <span className='font-bold w-full text-center md:text-start text-[#758694] text-xl'>Heliopolis, Cairo, Egypt</span>
                    <span className='font-bold w-full text-center md:text-start text-[#758694] text-xl mt-8'>Tel. (+20) 1200224507 </span>
                    <span className='font-bold w-full text-center md:text-start text-[#758694] text-xl'>Email: Steven.iskander@boslalearning.com</span>
                    <span className='font-bold w-full text-center md:text-start text-[#758694] text-xl'>Social:
                        <a href="https://www.instagram.com/boslalearning/" target="_blank" rel='noreferrer' className='text-g text-white' > <i className='fa fa-brands fa-instagram'></i> boslalearning </a>
                        <a href="https://www.facebook.com/profile.php?id=61564059651318" target="_blank" rel='noreferrer' className='text-g text-white' > <i className='fa fa-brands fa-facebook'></i> Bosla </a>
                          </span>

                    <h3 className='mt-7  w-full text-center md:text-start text-[#ff971d] font-bold text-xl'>This office is wheelchair accessible. </h3>
                    <p onClick={() => {
                        navigate('/contact')
                    }} className='text-3xl  text-center  py-5 mx-auto md:mx-0  w-80 bg-[--offwhite-color] hover:bg-[#fff6ef] text-[#758694] mt-8 cursor-pointer capitalize font-  my-5' >call us today</p>

                    {/* <div className='flex justify-around w-80 mx-auto md:mx-0  mt-5 '>
                        <i className='fa-brands fa-facebook fa-2x  cursor-pointer bg-blue-700 p-2 rounded-md hover:animate-pulse transition-all duration-100  '></i>
                        <i className='fa-brands fa-instagram fa-2x  cursor-pointer bg-gradient-to-r from-[#833ab4] via-[#fd1d1d] to-[#fcb045] p-2 rounded-md hover:animate-pulse transition-all duration-100  '></i>
                        <i className='fa-brands fa-linkedin fa-2x  cursor-pointer bg-blue-700 p-2 rounded-md hover:animate-pulse transition-all duration-100  '></i>
                    </div> */}
                </div>
                <div className='flex justify-center items-center md:w-1/2'>
                    <img src={require('./img.jpg')} className='w-full h-full  object-cover ' alt="" />
                </div>
            </div>
        </>
    )
}
