import { Avatar, Button, List, Tooltip, message } from 'antd';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

export default function CoursesList({ baseUrl, role }) {
    const [initLoading, setInitLoading] = useState(true);
    const [list, setList] = useState([]);
    const [approving, setApproving] = useState(false);

    const navigate = useNavigate();

    let headers = {
        Authorization: `Bearer ${localStorage.getItem(role === 0 ? 'superToken' : 'adminToken')}`,
    };

    const getData = async () => {
        axios.get(`${baseUrl}/api/Course/getAllCourses`, { headers })
            .then((res) => {
                setList(res.data.courses);
                setInitLoading(false);
            }).catch((err) => {
                if (err.response && err.response.status === 401) {
                    navigate('/login');
                }
                setInitLoading(false);
            });
    };

    useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleApproveCourse = async (id) => {
        setApproving(true);
        try {
            const response = await axios.post(`${baseUrl}/api/Course/ApproveCourse?Id=${id}`, {}, { headers });
            
            message.success('Course approved successfully');
            getData(); // Refresh the list after approving
        } catch (err) {
            if (err.response && err.response.status === 401) {
                navigate('/login');
            }
            message.error('Failed to approve course');
        } finally {
            setApproving(false);
        }
    };

    return (
        <div className='flex justify-center mt-28'>
            <div className='container'>
                <List
                    className="p-5 rounded-xl bg-[#3c4f5d]"
                    loading={initLoading}
                    itemLayout="horizontal"
                    dataSource={list}
                    renderItem={(item) => (
                        <List.Item
                            className=''

                            actions={[
                                <Button
                                    type='link'
                                    className='text-lg'
                                    onClick={() => handleApproveCourse(item.id)}
                                    disabled={item.isApproved || approving}
                                >
                                    {item.isApproved ?
                                        <Tooltip title='Approved'>
                                            <i className="fa fa-circle-check text-green-600"></i>
                                        </Tooltip>
                                        :
                                        <Tooltip title='Not Approved'>
                                            <i className="fa-regular fa-circle-check"></i>
                                        </Tooltip>
                                    }
                                </Button>
                            ]}
                        >
                            <List.Item.Meta
                                title={<p className='text-white '>{item.name}</p>}
                                avatar={<Avatar src={`${baseUrl}/api/Storage/GetImageById?id=${item.cover}`} size={'large'} />}

                                description={<span className='text-white '>{item.description}</span>}
                            />
                        </List.Item>
                    )}
                />
            </div>
        </div>
    );
}
