import React, { useEffect, useState } from 'react';
import gif from '../../../images/Success.gif';
import { PlusOutlined } from '@ant-design/icons';
import {
  Button, Form, Image, Input, Upload, Modal
} from 'antd';
import axios from 'axios';
import Cropper from 'react-easy-crop';
import './settings.css';
import ProviderProfile from '../Profile/ProviderProfile';
import getCroppedImg from '../../../Config/CropImage';

export default function Settings({ baseUrl }) {
  const [providerDataProfile, setProviderDataProfile] = useState();
  const [popUp, setPopUp] = useState(false);
  const [profilePhoto, setProfilePhoto] = useState('');
  const [coverPhoto, setCoverPhoto] = useState('');
  const [isCropModalVisible, setIsCropModalVisible] = useState(false);
  const [coverFile, setCoverFile] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [fileList, setFileList] = useState([]);

  const headers = {
    Authorization: `Bearer ${localStorage.getItem('providerToken')}`,
  };

  const GetMyData = () => {
    axios
      .get(`${baseUrl}/api/Provider/GetMyProfile`, { headers })
      .then((res) => {
        setProviderDataProfile(res.data);
        setProfilePhoto(res.data.imageId);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const handleCropConfirm = async () => {
    try {
      const croppedImage = await getCroppedImg(coverFile, croppedAreaPixels);

      // Convert Blob to File and append filename with extension
      const croppedImageFile = new File([croppedImage], 'cover-photo.jpg', { type: 'image/jpeg' });

      const formData = new FormData();
      formData.append('file', croppedImageFile);

      const response = await axios.post(`${baseUrl}/api/Storage/UploadImage`, formData, { headers });

      setCoverPhoto(response.data.id);

      // Update Ant Design Upload preview
      setFileList([
        {
          uid: '-1', // Unique identifier
          name: 'cover-photo.jpg',
          status: 'done',
          url: URL.createObjectURL(croppedImageFile),
        },
      ]);

      setIsCropModalVisible(false);
    } catch (err) {
      console.error('Error uploading cropped image:', err);
    }
  };



  const beforeUploadCoverPhoto = (file) => {
    const reader = new FileReader();
    reader.onload = () => {
      setCoverFile(reader.result);
      setIsCropModalVisible(true);
    };
    reader.readAsDataURL(file);
    return false; // Prevents automatic upload by Ant Design Upload
  };

  const EditProfileData = (value) => {
    value.imageId = profilePhoto;
    value.coverId = coverPhoto;

    axios
      .post(`${baseUrl}/api/Provider/EditProviderProfile`, value, { headers })
      .then((res) => {
        GetMyData();
        window.location.reload();
        setPopUp(true);
        setTimeout(() => {
          setPopUp(false);
        }, 1500);
      })
      .catch((err) => {
        console.error(err);
      });
  };
  const handleCancel = () => {
    setIsCropModalVisible(false);
    setFileList([]); // Clear the uploaded file
  };

  useEffect(() => {
    GetMyData();
  }, []);

  return (
    <>
      {popUp && (
        <div className="absolute top-0 bottom-0 right-0 left-0 flex justify-center items-center bg-black/75 -z-10">
          <img src={gif} className="w-56 h-56 rounded-full" alt="" />
        </div>
      )}

      <Modal
        title="Crop Cover Photo"
        open={isCropModalVisible}

        onCancel={() => setIsCropModalVisible(false)}
        footer={[
          <Button key="cancel" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="confirm" type="primary" onClick={handleCropConfirm}>
            Confirm
          </Button>,
        ]}
      >

        {coverFile && (
          <div className='h-80 w-full'>
            <Cropper
              image={coverFile}
              crop={crop}
              zoom={zoom}
              aspect={7 / 1}
              onCropChange={setCrop}
              onZoomChange={setZoom}
              onCropComplete={onCropComplete}
            />
          </div>

        )}
      </Modal>

      <div className="grid grid-cols-1 md:grid-cols-2 mx-auto min-h-screen px-5">
        <div className="hidden md:block">
          <ProviderProfile baseUrl={baseUrl} />
        </div>
        <div className="p-20 w-100 h-100">
          {providerDataProfile && (
            <Form initialValues={providerDataProfile} layout="vertical" onFinish={EditProfileData}>
              <div className="grid grid-cols-2">
                <Form.Item label="Upload Profile Photo" valuePropName="fileList">
                  <Upload
                    maxCount={1}
                    action={`${baseUrl}/api/Storage/UploadImage`}
                    headers={headers}
                    onChange={(e) => setProfilePhoto(e.file.response.id)}
                    listType="picture-card"
                  >
                    <button style={{ border: 0, background: 'none' }} type="button">
                      <PlusOutlined />
                      <div style={{ marginTop: 8 }}>Upload</div>
                    </button>
                  </Upload>
                </Form.Item>
                <Form.Item label="Upload Cover Photo" valuePropName="fileList">
                  <Upload
                    maxCount={1}
                    beforeUpload={beforeUploadCoverPhoto}
                    fileList={fileList}
                    onRemove={() => setFileList([])} // Allow removing the image
                    listType="picture-card"
                  >
                    <button style={{ border: 0, background: 'none' }} type="button">
                      <PlusOutlined />
                      <div style={{ marginTop: 8 }}>Upload</div>
                    </button>
                  </Upload>
                </Form.Item>

              </div>

              <Form.Item label="Title" name="title">
                <Input />
              </Form.Item>
              <Form.Item label="About Me" name="aboutMe">
                <Input />
              </Form.Item>
              <Form.Item
                label={
                  <>
                    <span>Personal Website</span>
                    <i className="fa-solid fa-user mx-2 fa-lg"></i>
                  </>
                }
                name="personalWebsite"
              >
                <Input />
              </Form.Item>
              <Form.Item
                label={
                  <>
                    <span>Facebook Link</span>
                    <i className="fa-brands fa-facebook mx-2 fa-lg"></i>
                  </>
                }
                name="facebookLink"
              >
                <Input />
              </Form.Item>
              <Form.Item
                label={
                  <>
                    <span>LinkedIn Link</span>
                    <i className="fa-brands fa-linkedin mx-2 fa-lg"></i>
                  </>
                }
                name="linkedInLink"
              >
                <Input />
              </Form.Item>
              <Button
                className="w-full bg-[--bg-secoundry-color] text-white text-lg p-5 hover:bg-[--dark-hover] hover:text-black"
                htmlType="submit"
              >
                Edit
              </Button>
            </Form>
          )}
        </div>
      </div>
    </>
  );
}


