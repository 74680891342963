import { Button, List, Modal, Form, Input, message, Select, DatePicker, Switch, Tooltip } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';

export default function Coupons({ baseUrl, role }) {
    const [initLoading, setInitLoading] = useState(true);
    const [list, setList] = useState([]);
    const [isAddModalVisible, setIsAddModalVisible] = useState(false);
    const [isEditModalVisible, setIsEditModalVisible] = useState(false);
    const [editItem, setEditItem] = useState(null);
    const [addLoading, setAddLoading] = useState(false);
    const [editLoading, setEditLoading] = useState(false);
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const { confirm } = Modal;

    let headers = {
        Authorization: `Bearer ${localStorage.getItem(role === 0 ? 'superToken' : 'adminToken')}`,
    };

    const getData = async () => {
        axios.get(`${baseUrl}/api/Coupon/GetCouponList`, { headers })
            .then((res) => {
                setList(res.data);
                setInitLoading(false);
            }).catch((err) => {
                if (err.response && err.response.status === 401) {
                    navigate('/login');
                }
                setInitLoading(false);
            });
    };

    useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const showAddModal = () => {
        setIsAddModalVisible(true);
        form.resetFields();
    };

    const showEditModal = (couponData) => {
        form.setFieldsValue({
            code: couponData.code,
            from: dayjs(couponData.from),
            to: dayjs(couponData.to),
            isActive: couponData.isActive,
            type: couponData.type === "PoundDiscount" ? 1 : 0,
            discountValue: couponData.discountValue,
        });
        setEditItem(couponData);
        setIsEditModalVisible(true);
    };

    const handleAddCoupon = async (values) => {
        const { code, from, to, isActive, type, discountValue } = values;
        try {
            setAddLoading(true);
            axios.post(`${baseUrl}/api/Coupon/AddCoupon`, {
                code,
                from,
                to,
                isActive,
                type,
                discountValue,
            }, { headers })
                .then(() => {
                    setIsAddModalVisible(false);
                    getData();
                }).catch(() => {
                    message.error('Failed to add Coupon');
                });
        } finally {
            setAddLoading(false);
        }
    };

    const handleEditCoupon = async (values) => {
        const { code, from, to, isActive, type, discountValue } = values;
        try {
            setEditLoading(true);
            axios.put(`${baseUrl}/api/Coupon/EditCoupon`, {
                id: editItem.id,
                code,
                from,
                to,
                isActive,
                type,
                discountValue,
            }, { headers })
                .then(() => {
                    setIsEditModalVisible(false);
                    getData();
                }).catch(() => {
                    message.error('Failed to edit Coupon');
                });
        } finally {
            setEditLoading(false);
        }
    };

    const handleDeleteCoupon = (id) => {
        confirm({
            title: 'Are you sure you want to delete this Coupon?',
            icon: <ExclamationCircleOutlined />,
            content: 'This action cannot be undone.',
            onOk() {
                axios.delete(`${baseUrl}/api/Coupon/DeleteCoupon?Id=${id}`, { headers })
                    .then(() => {
                        message.success('Coupon deleted successfully');
                        getData();
                    }).catch(() => {
                        message.error('Failed to delete Coupon');
                    });
            },
        });
    };

    const disablePastDates = (current) => {
        return current && current < dayjs().startOf('day');
    };

    return (
        <div className='flex justify-center mt-28'>
            <div className='container'>
                <div className='text-end'>
                    <button className='bg-[--btn-color] px-3 py-1 text-lg my-2 hover:bg-[--light-hover] rounded-lg' onClick={showAddModal}>
                        <i className='fa fa-add' /> Add Coupon
                    </button>
                </div>
                <List
                    className="p-5 rounded-xl bg-[#3c4f5d]"
                    loading={initLoading}
                    itemLayout="horizontal"
                    dataSource={list}
                    renderItem={(item) => (
                        <List.Item
                            actions={[
                                     
                                <Button type='link' className='text-[#2E8A99] text-lg' onClick={() => showEditModal(item)}>
                                    <i className='fa fa-edit'></i>
                                </Button>,
                                <Button type='link' className='text-lg ' danger onClick={() => handleDeleteCoupon(item.id)}>
                                    <i className='fa fa-trash'></i>
                                </Button>,
                            ]}
                        >
                            <List.Item.Meta
                                title={
                                    <p className='text-white '>   {item.isActive ?
                                        <Tooltip title='Active'>
                                            <i className="fa fa-circle-check text-green-600"></i>
                                        </Tooltip>
                                        :
                                        <Tooltip title='Not Active'>
                                            <i className="fa-regular fa-circle-check"></i>
                                        </Tooltip>
                                    } {item.code}</p>}
                                description={<p className='text-white'> {item?.from?.slice(0, 10)} / {item?.to?.slice(0, 10)}</p>}
                            />
                            <p className='text-white'>{item?.discountValue}{item.type === "PercentageDiscount" ? "%" : " EGP"}</p>
                            
                        </List.Item>
                    )}
                />
                <Modal
                    title="Add Coupon"
                    visible={isAddModalVisible}
                    onCancel={() => setIsAddModalVisible(false)}
                    footer={[
                        <Button key="back" onClick={() => setIsAddModalVisible(false)}>Cancel</Button>,
                        <Button key="submit" type="primary" loading={addLoading} onClick={() => form.submit()}>
                            Add
                        </Button>,
                    ]}
                >
                    <Form form={form} onFinish={handleAddCoupon}>
                        <Form.Item name="code" label="Code" rules={[{ required: true, message: 'Please enter the Coupon code' }]}>
                            <Input />
                        </Form.Item>
                        <Form.Item name="from" label="From" rules={[{ required: true, message: 'Please select the start date' }]}>
                            <DatePicker disabledDate={disablePastDates} />
                        </Form.Item>
                        <Form.Item name="to" label="To" rules={[{ required: true, message: 'Please select the end date' }]}>
                            <DatePicker disabledDate={disablePastDates} />
                        </Form.Item>
                        <Form.Item name="isActive" label="Active" valuePropName="checked">
                            <Switch />
                        </Form.Item>
                        <Form.Item name="type" label="Type" rules={[{ required: true, message: 'Please select the discount type' }]}>
                            <Select>
                                <Select.Option value={0}>Percentage (%)</Select.Option>
                                <Select.Option value={1}>EGP</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item
                            name="discountValue"
                            label="Discount Value"
                            rules={[
                                { required: true, message: 'Please enter the discount value' },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (getFieldValue('type') === 0 && value > 100) {
                                            return Promise.reject(new Error('Percentage discount cannot exceed 100%'));
                                        }
                                        return Promise.resolve();
                                    },
                                }),
                            ]}
                        >
                            <Input type="number" />
                        </Form.Item>
                    </Form>
                </Modal>

                {/* Edit Modal */}
                <Modal
                    title="Edit Coupon"
                    visible={isEditModalVisible}
                    onCancel={() => setIsEditModalVisible(false)}
                    footer={[
                        <Button key="back" onClick={() => setIsEditModalVisible(false)}>Cancel</Button>,
                        <Button key="submit" type="primary" loading={editLoading} onClick={() => form.submit()}>
                            Save
                        </Button>,
                    ]}
                >
                    <Form form={form} onFinish={handleEditCoupon}>
                        <Form.Item name="code" label="Code" rules={[{ required: true, message: 'Please enter the Coupon code' }]}>
                            <Input />
                        </Form.Item>
                        <Form.Item name="from" label="From" rules={[{ required: true, message: 'Please select the start date' }]}>
                            <DatePicker disabledDate={disablePastDates} />
                        </Form.Item>
                        <Form.Item name="to" label="To" rules={[{ required: true, message: 'Please select the end date' }]}>
                            <DatePicker disabledDate={disablePastDates} />
                        </Form.Item>
                        <Form.Item name="isActive" label="Active" valuePropName="checked">
                            <Switch />
                        </Form.Item>
                        <Form.Item name="type" label="Type" rules={[{ required: true, message: 'Please select the discount type' }]}>
                            <Select>
                                <Select.Option value={0}>Percentage</Select.Option>
                                <Select.Option value={1}>EGP</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item
                            name="discountValue"
                            label="Discount Value"
                            rules={[
                                { required: true, message: 'Please enter the discount value' },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (getFieldValue('type') === 0 && value > 100) {
                                            return Promise.reject(new Error('Percentage discount cannot exceed 100%'));
                                        }
                                        return Promise.resolve();
                                    },
                                }),
                            ]}
                        >
                            <Input type="number" />
                        </Form.Item>
                    </Form>
                </Modal>
            </div>
        </div>
    );
}
