import { Button, List, Modal, Form, Input, message } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

export default function Payments({ baseUrl ,role}) {
    const [initLoading, setInitLoading] = useState(true);
    const [list, setList] = useState([]);
    const [isAddModalVisible, setIsAddModalVisible] = useState(false);
    const [isEditModalVisible, setIsEditModalVisible] = useState(false);
    const [editItem, setEditItem] = useState(null);
    const [addLoading, setAddLoading] = useState(false);
    const [editLoading, setEditLoading] = useState(false);
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const { confirm } = Modal;

    let headers = {
        Authorization: `Bearer ${localStorage.getItem(role === 0 ? 'superToken' : 'adminToken')}`,
    };

    const getData = async () => {
        axios.get(`${baseUrl}/api/Payment/GetPayments`, { headers })
            .then((res) => {
                setList(res.data);
                setInitLoading(false);
            }).catch((err) => {
                if (err.response && err.response.status === 401) {
                    navigate('/login');
                }
                setInitLoading(false);
            });
    };

    useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const showAddModal = () => {
        setIsAddModalVisible(true);
        form.resetFields();
    };

    const showEditModal = (item) => {
        setEditItem(item);
        setIsEditModalVisible(true);
        form.setFieldsValue(item);
    };

    const handleAddPayment = async (values) => {
        const { name } = values;
        try {
            setAddLoading(true);
            axios.post(`${baseUrl}/api/Payment/AddPayment`, { name }, { headers })
                .then((res) => {
                    setIsAddModalVisible(false);
                    getData();
                }).catch((err) => {
                    message.error('Failed to add payment');
                });
        } catch (error) {
            if (error.response && error.response.status === 401) {
                navigate('/login');
            }
            
            message.error('Failed to add payment');
        } finally {
            setAddLoading(false);
        }
    };

    const handleEditPayment = async (values) => {
        const { name } = values;
        try {
            setEditLoading(true);
            axios.put(`${baseUrl}/api/Payment/EditPayment`, { id: editItem.id, name }, { headers })
                .then((res) => {
                    setIsEditModalVisible(false);
                    getData();
                }).catch((err) => {
                    if (err.response && err.response.status === 401) {
                        navigate('/login');
                    }
                    message.error('Failed to edit payment');
                });
        } catch (error) {
            if (error.response && error.response.status === 401) {
                navigate('/login');
            }
            
            message.error('Failed to edit payment');
        } finally {
            setEditLoading(false);
        }
    };

    const handleDeletePayment = (id) => {
        confirm({
            title: 'Are you sure you want to delete this payment?',
            icon: <ExclamationCircleOutlined />,
            content: 'This action cannot be undone.',
            onOk() {
                axios.delete(`${baseUrl}/api/Payment/DeletePayment?id=${id}`, { headers })
                    .then((res) => {
                        
                        message.success('Payment deleted successfully');
                        getData();
                    }).catch((err) => {
                        if (err.response && err.response.status === 401) {
                            navigate('/login');
                        }
                        
                        message.error('Failed to delete payment');
                    });
            },
            onCancel() {
            },
        });
    };

    return (
        <div className='flex justify-center mt-28'>
            <div className='container'>
                <div className='text-end'>
                    <button className='bg-[--btn-color] px-3 py-1 text-lg my-2 hover:bg-[--light-hover] rounded-lg' onClick={showAddModal}>
                        <i className='fa fa-add' /> Add payment
                    </button>
                </div>
                <List
                    className="p-5 rounded-xl bg-[#3c4f5d]"
                    loading={initLoading}
                    itemLayout="horizontal"
                    dataSource={list}
                    renderItem={(item) => (
                        <List.Item
                            actions={[
                                <Button type='link' className='text-[#2E8A99] text-lg' onClick={() => showEditModal(item)}>
                                    <i className='fa fa-edit'></i>
                                </Button>,
                                <Button type='link' className='text-lg ' danger onClick={() => handleDeletePayment(item.id)}>
                                    <i className='fa fa-trash'></i>
                                </Button>
                            ]}
                        >
                            <List.Item.Meta
                                title={<p className='text-white '>{item.name}</p>}
                            />
                        </List.Item>
                    )}
                />
                <Modal
                    title="Add Payment"
                    visible={isAddModalVisible}
                    onCancel={() => setIsAddModalVisible(false)}
                    footer={[
                        <Button key="back" onClick={() => setIsAddModalVisible(false)}>Cancel</Button>,
                        <Button key="submit" type="primary" loading={addLoading} onClick={() => form.submit()}>
                            Add
                        </Button>,
                    ]}
                >
                    <Form form={form} onFinish={handleAddPayment}>
                        <Form.Item name="name" label="Name" rules={[{ required: true, message: 'Please enter payment name' }]}>
                            <Input />
                        </Form.Item>
                    </Form>
                </Modal>
                <Modal
                    title="Edit Payment"
                    visible={isEditModalVisible}
                    onCancel={() => setIsEditModalVisible(false)}
                    footer={[
                        <Button key="back" onClick={() => setIsEditModalVisible(false)}>Cancel</Button>,
                        <Button key="submit" type="primary" loading={editLoading} onClick={() => form.submit()}>
                            Save
                        </Button>,
                    ]}
                >
                    <Form form={form} onFinish={handleEditPayment}>
                        <Form.Item name="name" label="Name" rules={[{ required: true, message: 'Please enter payment name' }]}>
                            <Input />
                        </Form.Item>
                    </Form>
                </Modal>
            </div>
        </div>
    );
}
