// src/api/getImageURL.js

import getBaseURL from "./BaseUrl";

const getImg = (imageId) => {
  if (!imageId) return ""; // Return an empty string if no ID provided
  return `${getBaseURL()}/api/Storage/GetImageById?id=${imageId}`;
};

export default getImg;
