import { Avatar, Button, List, Tooltip, message } from 'antd';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

export default function Providers({ baseUrl ,role}) {
  const [initLoading, setInitLoading] = useState(true);
  const [list, setList] = useState([]);

  const navigate = useNavigate();

  let headers = {
    Authorization: `Bearer ${localStorage.getItem(role===0?'superToken' :'adminToken')}`,
  };

  const getData = async () => {
    axios.get(`${baseUrl}/api/Provider/providers`, { headers })
      .then((res) => {
        
        setList(res.data);
        setInitLoading(false);
      }).catch((err) => {
        if (err.response && err.response.status === 401) {
          navigate('/login');
        }
        
        setInitLoading(false);
      });
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleApproveProvider = async (id) => {
    try {
      const response = await axios.post(`${baseUrl}/api/Provider/ToggleApproving?userId=${id}`, { }, { headers });
      
      getData(); // Refresh the list after approving
    } catch (err) {
      if (err.response && err.response.status === 401) {
        navigate('/login');
      }
      
      message.error('Failed to approve provider');
    } finally {
    }
  };

  return (
    <div className='flex justify-center mt-28'>
      <div className='container'>
        <List
          className="p-5 rounded-xl bg-[#3c4f5d]"
          loading={initLoading}
          itemLayout="horizontal"
          dataSource={list}
          renderItem={(item) => (
            <List.Item
              className=''
              
              actions={[
                <Button
                  type='link'
                  className='text-lg'
                  onClick={() => handleApproveProvider(item.id)}
                >
                  {item.isApproved ?
                    <Tooltip title='Approved'>
                      <i className="fa fa-circle-check text-green-600"></i>
                    </Tooltip>
                    :
                    <Tooltip title='Not Approved'>
                      <i className="fa-regular fa-circle-check"></i>
                    </Tooltip>
                  }
                </Button>,
                <Button type='link' className='text-lg ' danger><i className='fa fa-trash'></i> </Button>
              ]}
            >
              <List.Item.Meta
                avatar={<Avatar src={`${baseUrl}/api/Storage/GetImageById?id=${item.image}`} />}
                title={<p className='text-white '>{item.userName}</p>}
                description={<span className='text-white '>{item.email}</span>}
              />
              <p className='text-white '>{item.phoneNumber}</p>
            </List.Item>
          )}
        />
      </div>
    </div>
  );
}
