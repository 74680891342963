import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import './CourseDetails.css'
import axios from 'axios';
import { motion } from 'framer-motion';
import LoginPopUp from '../../../Components/PopUp/LoginPopUp.jsx';
import { Button, Input, Modal, Space, notification } from 'antd';

export default function CourseDetails({ baseUrl }) {
    const navigate = useNavigate()
    const [courseDetails, setCourseDetails] = useState('')
    const [popUp, setPopUp] = useState(false)
    const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
    const [couponCode, setCouponCode] = useState('');
    const [finalPrice, setFinalPrice] = useState(courseDetails?.price);
    const [isCouponApplied, setIsCouponApplied] = useState(false);
    const [CouponDetails, setCouponDetails] = useState();
    const [couponError, setCouponError] = useState(); // State to track coupon errors

    const token = localStorage.getItem('userToken')
    const headers = {
        Authorization: `Bearer ${token}`,
    }

    const { id } = useParams()

    const animationVariants = {
        visible: { x: 0, opacity: 1 },
        hidden: { x: -200, opacity: 0 },
    };

    const smallDevice = window.innerWidth < 768;
    function CheckAuthorize() {
        if (!token) {
            setPopUp(true);
        } else {
            courseDetails?.isOwned ? navigate(`/watch/${id}`) : setIsPaymentModalOpen(true); // Open the payment modal
            setPopUp(false);
        }
    }

    async function applyCoupon() {
        if (!couponCode.trim()) return;

        const data = {
            courseId: id,
            code: couponCode,
        };

             await axios.post(`${baseUrl}/api/Coupon/CheckCoupon`, data, { headers })
             .then((res) => {
                 setCouponError(''); // Clear any previous error
                 setFinalPrice(res?.data?.newPrice);
                 setIsCouponApplied(true);
                 setCouponDetails(res?.data?.CouponDetails);
             })
             .catch((err) => {
                 setCouponError(err.response.data[0]); // Set error message
                 setFinalPrice()
                 setIsCouponApplied(false);

             })
         
    }

    async function craetePaymentLink() {
        const data = {
            courseId: id,
            couponCode: isCouponApplied ? couponCode : undefined,
        };

        try {
            const res = await axios.post(`${baseUrl}/api/Payment/getPaymentLink`, data, { headers });
            window.location.href = res.data.link;
        } catch (err) {
            console.error("Failed to create payment link:", err);
            alert("Unable to generate payment link. Please try again.");
        }
    }

    async function getCourseDetails() {
        await axios.get(`${baseUrl}/api/Course/GetCourseById?Id=${id}`, localStorage.getItem('userToken') ? { headers } : '').then((res) => {
            setCourseDetails(res.data)
        }).catch((err) => {

        })
    }

    useEffect(() => {
        getCourseDetails()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])


    return (
        <div className=''>
            {popUp && (
                <LoginPopUp baseUrl={baseUrl} setPopUp={setPopUp} />
            )}
            <div className=' mx-auto sm:grid sm:grid-cols-2 grid-cols-1 flex flex-col-reverse w-full overflow-hidden '>
                <motion.div
                    initial={smallDevice ? { y: -200, opacity: 0 } : { x: -200, opacity: 0 }}
                    whileInView={smallDevice ? { y: 0, opacity: 1 } : { x: 0, opacity: 1 }}
                    transition={{ type: "spring", stiffness: 60, delay: .5 }}
                    variants={animationVariants}
                    viewport={!smallDevice ? { once: true, amount: 0.3 } : undefined}
                    className=' sm:p-10 p-2 flex flex-col justify-center '>
                    <p className='md:text-4xl text-3xl sm:text-start text-center font-bold text-[--offwhite-color] '>{courseDetails?.name} <span className='text-green-500 text-lg'>{courseDetails?.isOwned ? "(Owned)" : ''} </span></p>
                    <p onClick={() => navigate(`/provider/${courseDetails?.providerId}`)} className='text-[16px] sm:text-start text-center cursor-pointer font-bold text-[--yellow]'>Provided By : {courseDetails?.providerTitle ? courseDetails?.providerTitle : courseDetails?.providerName}</p>
                    <div className='grid sm:grid-cols-2 grid-cols-1  mt-3 gap-5'>
                        <p className='bg-[--offwhite-color] sm:block hidden hover:opacity-90 cursor-pointer text-[#405d72]  p-3 w-full text-center underline' onClick={() => navigate(`/provider/${courseDetails?.providerId}`)}>Provider Details</p>
                        <p className='bg-[--offwhite-color] sm:block hidden hover:opacity-90  text-[#405d72]  p-3 w-full text-center underline cursor-pointer' onClick={() => navigate('/testimonials')}>Testimonials</p>
                    </div>
                    {courseDetails.isOwned ?
                        <p onClick={() => {
                            CheckAuthorize()
                        }} className='bg-[--offwhite-color] hover:opacity-90 cursor-pointer text-[#405d72] w-full p-3 underline text-center sm:mt-5'>Watch Now</p>
                        :
                        <p
                            onClick={() => {
                                CheckAuthorize();
                            }}
                            className="bg-[--offwhite-color] font-bold text-lg hover:opacity-90 cursor-pointer text-[#405d72] w-full p-3 underline text-center sm:mt-5"
                        >
                            Subscribe Now
                        </p>
                    }

                </motion.div>
                <motion.img
                    initial={smallDevice ? '' : { x: 200, opacity: 0 }}
                    whileInView={{ x: 0, opacity: 1 }}
                    transition={{ type: "spring", stiffness: 60, delay: .5 }}
                    variants={animationVariants}
                    viewport={!smallDevice ? { once: true, amount: 0.3 } : undefined}
                    src={`${baseUrl}/api/Storage/GetImageById?id=${courseDetails?.cover}`} className='w-full h-[60dvh]   object-cover  ' alt="Course Cover" />
            </div>
            <div className=' mx-auto grid sm:grid-cols-2   overflow-hidden '>
                <motion.img
                    initial={smallDevice ? '' : { x: -200, opacity: 0 }}
                    whileInView={{ x: 0, opacity: 1 }}
                    transition={{ type: "spring", stiffness: 60, delay: .5 }}
                    variants={animationVariants}
                    viewport={!smallDevice ? { once: true, amount: 0.3 } : undefined}
                    src={`${baseUrl}/api/Storage/GetImageById?id=${courseDetails?.descriptionImageId}`}
                    className='w-full   object-cover sm:inline-block hidden '
                    alt="" />
                <motion.div
                    initial={{ x: 200, opacity: 0 }}
                    whileInView={{ x: 0, opacity: 1 }}
                    transition={{ type: "spring", stiffness: 60, delay: .5 }}
                    variants={animationVariants}
                    viewport={!smallDevice ? { once: true, amount: 0.3 } : undefined} className=' sm:px-10 p-3 flex flex-col space-y-3 sm:space-y-8'>
                    <h1 className='text-[22px] font-bold text-[--offwhite-color] sm:hidden'>About Course:</h1>
                    <p className='text-[16px] text-start text-[--offwhite-color]'>
                        {courseDetails?.descriptionText}
                    </p>
                </motion.div>
                <img
                    src={`${baseUrl}/api/Storage/GetImageById?id=${courseDetails?.descriptionImageId}`}
                    className='w-full h-[10dvh]  object-cover sm:hidden bg-gray-500/50 '
                    alt="" />
            </div>
            <Modal
                open={isPaymentModalOpen}
                onCancel={() => setIsPaymentModalOpen(false)}
                footer={null}
                centered
            >
                <div className="mt-5 flex items-center">
                    <h1 className={`text-[22px] mr-3 `}>
                        Price:  {finalPrice && (
                            <span className='text-[22px] text-green-500'>
                                {finalPrice} Egp
                            </span>
                        )} <span className={`${finalPrice ? 'line-through text-gray-500 align-sub text-[18px]' : ''}`}> {courseDetails?.price} Egp</span>
                    </h1>
                  
                </div>

                <div className='mt-5'>
                    <label htmlFor="coupon">Do you have a coupon?</label>
                    <Space.Compact style={{ width: '100%' }} className=''>
                        <Input
                            id="coupon"
                            value={couponCode}
                            onChange={(e) => setCouponCode(e.target.value)}
                            placeholder="Enter your coupon code"
                            className="" />
                        <Button onClick={applyCoupon} type="primary">Apply</Button>
                    </Space.Compact>
                    {couponError &&
                    <div className='bg-red-500 bg-opacity-50 rounded-xl p-2 mt-1'>

                  <p className="text-white">{couponError}</p>
                    </div>
                    }

                </div>

             

                <div className="mt-4 space-y-2">
                    <Button
                        type="primary"
                        block
                        onClick={craetePaymentLink}
                    >
                        Pay Now
                    </Button>
                </div>
            </Modal>
        </div>
    )
}
