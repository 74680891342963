import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { Avatar, Card, Carousel } from 'antd';
import axios from 'axios';
import getBaseURL from '../../Config/BaseUrl';
import getImg from '../../Config/GetImg';

export default function TestimonialCard() {
    const [Reviews, setReviews] = useState([]);
    const baseUrl = getBaseURL();

    async function getReviews() {
        try {
            const res = await axios.get(`${baseUrl}/api/Review/Get`);
            setReviews(res.data);
        } catch (err) {
            console.error('Error fetching reviews:', err);
        }
    }

    useEffect(() => {
        getReviews();
    }, []);

    const isScrollable = Reviews.length > 3; // Check if carousel should scroll

    return (
        <div className='w-full '>
            <motion.h1
                initial={{ y: 50, opacity: 0 }}
                whileInView={{ y: 0, opacity: 1 }}
                transition={{ type: 'spring', stiffness: 60 }}
                viewport={{ once: true }}
                className='font-bold md:text-6xl text-4xl text-center mb-10'
            >
                Testimonials and Reviews
            </motion.h1>

            {/* Carousel for large screens */}
            <motion.div
                initial={{ y: 50, opacity: 0 }}
                whileInView={{ y: 0, opacity: 1 }}
                transition={{ type: 'spring', stiffness: 60 }}
                viewport={{ once: true }}
                className="hidden sm:block"
            >
                <Carousel
                    dots={false}
                    arrows={isScrollable} // Disable arrows if not scrollable
                    autoplay={isScrollable} // Disable autoplay if not scrollable
                    slidesToShow={3}
                    slidesToScroll={1}
                >
                    {Reviews.map((review) => (
                        <div key={review.id} className="px-2"> {/* Added horizontal padding */}
                            <Card
                                className="w-full mx-auto text-center bg-[--offwhite-color] text-[--main-color] shadow-md border-black flex flex-col justify-between"
                                style={{ height: '100%' }} // Ensure equal height
                            >
                                <Avatar
                                    className="mb-5"
                                    src={getImg(review.photoId)}
                                    size={100}
                                    alt={review.name}
                                />
                                <h3 className="text-lg md:text-xl min-h-32">{review.description}</h3>
                                <p className="text-[--yellow] font-bold text-lg mt-2">- {review.name} -</p>
                            </Card>
                        </div>
                    ))}
                </Carousel>
            </motion.div>

            {/* Carousel for small screens */}
            <motion.div
                initial={{ y: 50, opacity: 0 }}
                whileInView={{ y: 0, opacity: 1 }}
                transition={{ type: 'spring', stiffness: 60 }}
                viewport={{ once: true }}
                className="block sm:hidden"
            >
                <Carousel
                    dots={true}
                    arrows={false}
                    autoplay
                    slidesToShow={1}
                    slidesToScroll={1}
                >
                    {Reviews.map((review) => (
                        <div key={review.id} className="py-7 px-0"> {/* Added horizontal and vertical padding */}
                            <Card
                                className="w-full mx-auto text-center bg-[--offwhite-color] text-[--main-color] shadow-md border-black flex flex-col justify-between"
                                style={{ height: '100%' }} // Ensure equal height
                            >
                                <Avatar
                                    className="mb-5 mx-auto"
                                    src={getImg(review.photoId)}
                                    size={100}
                                    alt={review.name}
                                />
                                <h3 className="text-lg md:text-xl min-h-52">{review.description}</h3>
                                <p className="text-[--yellow] font-bold text-lg mt-2">- {review.name} -</p>
                            </Card>
                        </div>
                    ))}
                </Carousel>
            </motion.div>
        </div>
    );
}
