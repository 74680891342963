import { useEffect, useState } from 'react';
import './App.css';
import Layout from './Pages/User/Layout/Layout';
import Home from './Pages/User/Home/Home';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import LoadingScreen from './Components/LoadingScreen/LoadingScreen';
import Login from './Pages/Auth/Login';
import SignUp from './Pages/Auth/SignUp';
import About from './Pages/User/About Us/About';
import Courses from './Pages/User/Categories/UserCategories.jsx';
import AdminLayout from './Pages/Admin/Layout/AdminLayout';
import Providers from './Pages/Admin/Providers/Providers';
import Categories from './Pages/Admin/Categories/Categories';
import Payments from './Pages/Admin/Payment/Payments';
import NotFound from './Pages/404/NotFound';
import CoursesList from './Pages/Admin/Courses/CoursesList';
import ProtectedRoute from './Components/ProtectedRoute/ProtectedRoute';
import Admins from './Pages/Admin/Admins/Admins';
import Contact from './Pages/User/Contact/Contact';
import ProviderLayout from './Pages/Provider/Layout/ProviderLayout';
import Dashboard from './Pages/Provider/DashBoard/Dashboard';
import Course from './Pages/Provider/Course/Course';
import ProviderProfile from './Pages/Provider/Profile/ProviderProfile';
import ExploreCourses from './Pages/User/ExploreCourses/ExploreCourses.jsx';
import WatchCourse from './Pages/User/WatchCourse/WatchCourse.jsx';
import ProfileUser from './Pages/User/ProfileUser/ProfileUser.jsx';
import CategoriesCoursesDetails from './Pages/User/CategoriesCoursesDetails/CategoriesCoursesDetails.jsx';
import Settings from './Pages/Provider/Settings/Settings';
import WishList from './Pages/User/WishList/WishList.jsx';
import GetInTouch from './Pages/User/GetInTouch/GetInTouch.jsx';
import CourseDetails from './Pages/User/CourseDetails/CourseDetails.jsx';
import UserCategories from './Pages/User/Categories/UserCategories.jsx';
import Payment from './Pages/User/Payment/Payment.jsx';
import Testimonials from './Pages/User/Testimonials/Testimonials.jsx';
import Checkout from './Pages/User/Checkout/Checkout.jsx';
import ReactPlayer from 'react-player';
import Coupons from './Pages/Admin/Coupons/Coupons.jsx';
import Reviews from './Pages/Admin/Reviews/Reviews.jsx';

function App() {
  const [loading, setloading] = useState(true);

  useEffect(() => {
    setloading(true)
    setTimeout(() => {
      setloading(false)
    }, 4000);
  }, []);
  //  const baseUrl = "http://62.72.16.64:8018"; // old
  const baseUrl = "https://api.boslalearning.com"; // production
  // const baseUrl = "https://localhost:7236"; // local
  const routers = createBrowserRouter([
    {
      path: "/login",
      element: <Login baseUrl={baseUrl} />
    },
    {
      path: "/sign-up",
      element: <SignUp baseUrl={baseUrl} />
    },
    {
      element: <Layout baseUrl={baseUrl} />, children: [
        {
          index: true,
          element: <Home baseUrl={baseUrl} />
        },
        {
          path: "/home",
          element: <Home baseUrl={baseUrl} />
        },
    
        {
          path: "/exploreCourses",
          element: <ExploreCourses baseUrl={baseUrl} />
        },
        {
          path: "/categories",
          element: <UserCategories baseUrl={baseUrl} />
        },
        {
          path: "/Courses/:id",
          element: <CategoriesCoursesDetails baseUrl={baseUrl} />
        },
        {
          path: "/profileUser",
          element: <ProfileUser baseUrl={baseUrl} />
        },
        {
          path: "/Course/:id",
          element: <CourseDetails baseUrl={baseUrl} />
        },
        {
          path: "/watch/:id",
          element: <WatchCourse baseUrl={baseUrl} />
        },
        {
          path: "/Courses",
          element: <Courses baseUrl={baseUrl} />
        },
        {
          path: "/about",
          element: <About baseUrl={baseUrl} />
        },
        {
          path: "/testimonials",
          element: <Testimonials baseUrl={baseUrl} />
        },
        {
          path: "/payment/:id",
          element: <Payment baseUrl={baseUrl} />
        },
        {
          path: "/provider/:id",
          element: <ProviderProfile show={true} baseUrl={baseUrl} />
        },
        {
          path: "/wishlist",
          element: <WishList baseUrl={baseUrl} />
        },
        {
          path: "/contact",
          element: <Contact baseUrl={baseUrl} />
        },
        {
          path: "/getintouch",
          element: <GetInTouch baseUrl={baseUrl} />
        },
        {
          path: "/checkout/:id",
          element: <Checkout baseUrl={baseUrl} />
        },
      ]
    },
    {
      path: 'admin',
      element: <ProtectedRoute role={1}> <AdminLayout /> </ProtectedRoute>,
      children: [
        {
          index: true,
          path: "providers",
          element: <ProtectedRoute role={1}> <Providers baseUrl={baseUrl} /> </ProtectedRoute>
        },
        {
          path: "Categories",
          element: <ProtectedRoute role={1}> <Categories baseUrl={baseUrl} /> </ProtectedRoute>
        },
        {
          path: "payments",
          element: <ProtectedRoute role={1}> <Payments baseUrl={baseUrl} /> </ProtectedRoute>
        },
        {
          path: "courses",
          element: <ProtectedRoute role={1}> <CoursesList baseUrl={baseUrl} /> </ProtectedRoute>
        },
        {
          path: "Coupons",
          element: <ProtectedRoute role={1}> <Coupons baseUrl={baseUrl} /> </ProtectedRoute>
        },
        {
          path: "Reviews",
          element: <ProtectedRoute role={1}> <Reviews baseUrl={baseUrl} /> </ProtectedRoute>
        },
      ]
    },
    {
      path: 'superAdmin',
      element: <ProtectedRoute role={0}> <AdminLayout role={0} /> </ProtectedRoute>,
      children: [
        {
          index: true,
          path: "admins",
          element: <ProtectedRoute role={0}> <Admins baseUrl={baseUrl} role={0} /> </ProtectedRoute>
        },
        {
          path: "providers",
          element: <ProtectedRoute role={0}> <Providers baseUrl={baseUrl} role={0} /> </ProtectedRoute>
        },
        {
          path: "Categories",
          element: <ProtectedRoute role={0}> <Categories baseUrl={baseUrl} role={0} /> </ProtectedRoute>
        },
        {
          path: "payments",
          element: <ProtectedRoute role={0}> <Payments baseUrl={baseUrl} role={0} /> </ProtectedRoute>
        },
        {
          path: "courses",
          element: <ProtectedRoute role={0}> <CoursesList baseUrl={baseUrl} role={0} /> </ProtectedRoute>
        },
        {
          path: "Coupons",
          element: <ProtectedRoute role={0}> <Coupons baseUrl={baseUrl} /> </ProtectedRoute>
        },
        {
          path: "Reviews",
          element: <ProtectedRoute role={0}> <Reviews baseUrl={baseUrl} /> </ProtectedRoute>
        },

      ]
    },
    {
      path: 'provider',
      element: <ProtectedRoute role={2}> <ProviderLayout baseUrl={baseUrl} role={2} /> </ProtectedRoute>,
      children: [
        {
          index: true,
          path: "home",
          element: <ProtectedRoute role={2}> <Dashboard baseUrl={baseUrl} role={2} /> </ProtectedRoute>
        },
        {
          path: "course/:id?",
          element: <ProtectedRoute role={2}> <Course baseUrl={baseUrl} role={2} /> </ProtectedRoute>
        },
        {
          path: "profile?",
          element: <ProtectedRoute role={2}> <ProviderProfile baseUrl={baseUrl} role={2} /> </ProtectedRoute>
        },
        {
          path: "settings",
          element: <ProtectedRoute role={2}> <Settings baseUrl={baseUrl} role={2} /> </ProtectedRoute>
        },
      ]
    },
    {
      path: '*',
      element: <NotFound />
    },
  ])

  return <>
    {loading ? <LoadingScreen /> : null}
    <RouterProvider router={routers} />
   
  
  </>
}

export default App;