import { FileTextOutlined, LockOutlined, UndoOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import React, { useState } from 'react'
import logo from "../../images/logo.svg"
import { useNavigate } from 'react-router-dom';

export default function Footer() {
    const [isTermsModalVisible, setTermsModalVisible] = useState(false);
    const [isPrivacyModalVisible, setPrivacyModalVisible] = useState(false);
    const [isRefundModalVisible, setRefundModalVisible] = useState(false);
    const navigate = useNavigate()
    return (

        <>
            <footer className="bg-[#3c4f5d]  ">
                <div className=" flex-wrap justify-between items-start px-4 py-5 mx-auto sm:px-6 lg:px-8 xl:px-10 sm:flex hidden">
                    {/* Section 1 */}
                    <div className=" flex-col flex-wrap justify-center gap-y-6 w-full md:w-auto text-center md:text-left flex">
                        <img
                            src={logo}
                            alt="Logo"
                            className="w-44 mx-auto md:mx-0 cursor-pointer"
                            onClick={() => navigate('/home')}
                        />
                        <a
                            className="text-base leading-6 text-gray-400 hover:text-gray-300 cursor-pointer "
                            href={`#who-we-are`}
                            onClick={() => { navigate('/home') }}
                        >
                            About Us
                        </a>
                        <div
                            className="text-base leading-6 text-gray-400 hover:text-gray-300 cursor-pointer "
                            onClick={() => { navigate('/getintouch') }}
                        >
                            Contact
                        </div>
                    </div>

                    {/* Section 2 */}
                    <div className=" flex-col flex-wrap justify-center gap-y-6 w-full md:w-auto text-center md:text-left mt-6 md:mt-0 flex">
                        <div className="leading-6 text-[#F7E7DC] text-xl font-bold">
                            Explore
                        </div>
                        <a
                            className="text-base leading-6 text-gray-400 hover:text-gray-300 cursor-pointer"
                            href={`#our-courses`}
                            onClick={() => { navigate('/home') }}    
                        >
                            Browse Courses
                        </a>
                        <a
                            className="text-base leading-6 text-gray-400 hover:text-gray-300 cursor-pointer"
                            onClick={() => { navigate('/home') }}
                            href="#testimonials"
                        >
                            Testimonials & Reviews
                        </a>
                    </div>

                    {/* Section 3 */}
                    <div className=" flex-col flex-wrap justify-center gap-y-6 w-full md:w-auto text-center md:text-left mt-6 md:mt-0 flex">
                        <div className="leading-6 text-[#F7E7DC] text-xl font-bold">
                            Social
                        </div>
                        <a
                            href="https://www.facebook.com/profile.php?id=61564059651318"
                            className="text-base leading-6 text-gray-400 hover:text-gray-300 cursor-pointer no-underline"
                        >
                            <i className="fa fa-brands fa-facebook"></i> Facebook
                        </a>
                        <a
                            href="https://www.instagram.com/boslalearning/"
                            className="text-base leading-6 text-gray-400 hover:text-gray-300 cursor-pointer no-underline"
                        >
                            <i className="fa fa-brands fa-instagram"></i> Instagram
                        </a>
                    </div>

                    {/* Section 4 */}
                    <div className=" flex-col flex-wrap justify-center gap-y-6 w-full md:w-auto text-center md:text-left mt-6 md:mt-0 flex">
                        <div className="leading-6 text-[#F7E7DC] text-xl font-bold ">
                            Policies
                        </div>
                        <div
                            className="text-base leading-6 text-gray-400 hover:text-gray-300 cursor-pointer"
                            onClick={() => setRefundModalVisible(true)}
                        >
                            Refund Policy
                        </div>
                        <div
                            className="text-base leading-6 text-gray-400 hover:text-gray-300 cursor-pointer"
                            onClick={() => setTermsModalVisible(true)}
                        >
                            Terms and Conditions
                        </div>
                        <div
                            className="text-base leading-6 text-gray-400 hover:text-gray-300 cursor-pointer"
                            onClick={() => setPrivacyModalVisible(true)}
                        >
                            Privacy Policy
                        </div>
                    </div>
                </div>
                <div className="sm:hidden flex flex-col items-center px-4 py-5">
                    {/* Logo */}
                    <img
                        src={logo}
                        alt="Logo"
                        className="w-36 mb-4 cursor-pointer"
                        onClick={() => navigate('/home')}
                    />

                    {/* Policies */}
                    <div className="text-center space-y-4">
                        <div
                            className="text-sm leading-5 text-gray-400 hover:text-gray-300 cursor-pointer"
                            onClick={() => setRefundModalVisible(true)}
                        >
                            Refund Policy
                        </div>

                        <div
                            className="text-sm leading-5 text-gray-400 hover:text-gray-300 cursor-pointer"
                            onClick={() => setPrivacyModalVisible(true)}
                        >
                            Privacy Policy
                        </div>
                        <div
                            className="text-sm leading-5 text-gray-400 hover:text-gray-300 cursor-pointer"
                            onClick={() => setTermsModalVisible(true)}
                        >
                            Terms and Conditions
                        </div>
                    </div>
                </div>
                <div className="py-2 bg-[#2e3d47]">
                    <p className="text-base leading-6 text-center text-gray-400">
                        © 2021 Zentix Software. All rights reserved.
                    </p>
                </div>
            </footer>


            {/* Privacy Policy Modal */}
            <Modal
                title={
                    <h2 className="flex items-center justify-center shadow-sm border-b border-gray-200 pb-2 text-lg font-semibold">
                        <LockOutlined className="mr-2 text-green-500" />
                        Privacy Policy
                    </h2>
                }
                visible={isPrivacyModalVisible}
                onCancel={() => setPrivacyModalVisible(false)}
                width={700}
                centered
                footer={<p className='border-t border-gray-200 pt-2'><strong>Last Updated:</strong> 19/11/2024</p>}

            >
                <div className="text-sm  max-h-[80vh] overflow-auto space-y-4">
                    <h3 className="font-semibold text-lg">1. Information We Collect</h3>
                    <ul className="list-disc ml-5">
                        <li><strong>Personal Information:</strong> Name, email address, phone number, and payment details.</li>
                        <li><strong>Usage Data:</strong> IP address, browser type, pages visited, etc.</li>
                        <li><strong>Cookies and Tracking:</strong> Used for enhancing user experience.</li>
                    </ul>

                    <h3 className="font-semibold text-lg">2. How We Use Your Information</h3>
                    <ul className="list-disc ml-5">
                        <li>To create and manage your account.</li>
                        <li>To process transactions and communicate updates.</li>
                        <li>To improve our website and services.</li>
                    </ul>

                    <h3 className="font-semibold text-lg">3. Sharing Your Information</h3>
                    <p>We do not sell or rent your personal information. It may be shared with service providers or for legal compliance.</p>

                    <h3 className="font-semibold text-lg">4. Data Security</h3>
                    <p>We implement measures to protect your data but cannot guarantee complete security.</p>

                    <h3 className="font-semibold text-lg">5. Your Rights</h3>
                    <ul className="list-disc ml-5">
                        <li>The right to access, correct, or delete your data.</li>
                    </ul>

                    <h3 className="font-semibold text-lg">6. Third-Party Links</h3>
                    <p>We are not responsible for privacy practices of third-party websites.</p>

                    <h3 className="font-semibold text-lg">7. Contact Us</h3>
                    <p>For questions, contact us at <a href="mailto:info@boslalearning.com" className="text-blue-900 underline">info@boslalearning.com</a>.</p>
                </div>
            </Modal>

            {/* Refund Policy Modal */}
            <Modal
                title={
                    <h2 className="flex items-center justify-center shadow-sm border-b border-gray-200 pb-2 text-lg font-semibold">
                        <UndoOutlined className="mr-2 text-red-500" />
                        Refund Policy
                    </h2>
                }
                visible={isRefundModalVisible}
                onCancel={() => setRefundModalVisible(false)}
                footer={null}
                width={700}
                centered
            >
                <div className="text-sm space-y-4">
                    <h3 className="font-semibold text-lg">No Refunds</h3>
                    <p>Due to the nature of our online courses, we cannot offer refunds after accessing a course.</p>

                    <h3 className="font-semibold text-lg">Exceptions</h3>
                    <ul className="list-disc ml-5">
                        <li>Technical issues that prevent access.</li>
                        <li>Significant changes to course content.</li>
                    </ul>

                    <h3 className="font-semibold text-lg">Requesting a Refund</h3>
                    <p>Contact us at <a href="mailto:info@boslalearning.com" className="text-blue-900 underline">info@boslalearning.com</a> or call +01200224507. Provide details of the issue.</p>
                </div>
            </Modal>
            {/* Terms Modal */}
            <Modal
                title={
                    <h2 className="flex items-center justify-center shadow-sm border-b border-gray-200 pb-2 text-lg font-semibold">
                        <FileTextOutlined className="mr-2 text-blue-500" />
                        Terms and Conditions
                    </h2>
                }
                visible={isTermsModalVisible}
                onCancel={() => setTermsModalVisible(false)}
                footer={<p className='border-t border-gray-200 pt-2'><strong>Last Updated:</strong> 19/11/2024</p>}

                width={700}
                centered

            >
                <div className="text-sm  space-y-4 max-h-[80vh] overflow-auto">
                    <p>Welcome to Bosla. By accessing or using our website and services, you agree to comply with and be bound by the following terms and conditions. Please read them carefully.</p>

                    <h3 className="font-semibold text-lg">1. Acceptance of Terms</h3>
                    <p>By using Bosla, you acknowledge that you have read, understood, and agree to be bound by these Terms and Conditions. If you do not agree, please do not use our services.</p>

                    <h3 className="font-semibold text-lg">2. User Accounts</h3>
                    <p>You agree to:</p>
                    <ul className="list-disc ml-5">
                        <li>Provide accurate and complete information during the registration process.</li>
                        <li>Maintain the confidentiality of your account credentials and notify us immediately of any unauthorized use.</li>
                        <li>Be responsible for all activities that occur under your account.</li>
                    </ul>

                    <h3 className="font-semibold text-lg">3. Course Access & Usage</h3>
                    <p>Your access to course materials is subject to the following:</p>
                    <ul className="list-disc ml-5">
                        <li>Access and use the course materials solely for personal, non-commercial purposes.</li>
                        <li>Do not reproduce, distribute, or share course materials without explicit permission from Bosla.</li>
                    </ul>

                    <h3 className="font-semibold text-lg">4. Payments and Refunds</h3>
                    <p>All fees must be paid in full at the time of purchase. Refund Policy: No refund or exchange is accepted.</p>

                    <h3 className="font-semibold text-lg">5. Intellectual Property Rights</h3>
                    <p>All content hosted on Bosla is protected by intellectual property laws and is owned by Bosla or its licensors. Unauthorized use is prohibited.</p>

                    <h3 className="font-semibold text-lg">6. User Conduct</h3>
                    <p>You agree to refrain from:</p>
                    <ul className="list-disc ml-5">
                        <li>Harassing, bullying, or intimidating other users.</li>
                        <li>Disrupting the platform's operations or infringing upon other users’ rights.</li>
                    </ul>

                    <h3 className="font-semibold text-lg">7. Termination</h3>
                    <p>We reserve the right to suspend or terminate your account for violations or unlawful activities.</p>

                    <h3 className="font-semibold text-lg">8. Disclaimer of Warranties</h3>
                    <p>Bosla provides its services on an "as-is" basis and makes no warranties regarding course quality or availability.</p>

                    <h3 className="font-semibold text-lg">9. Limitation of Liability</h3>
                    <p>Bosla is not liable for any direct, indirect, or incidental damages arising from the use of its platform or course materials.</p>

                    <h3 className="font-semibold text-lg">10. Governing Law</h3>
                    <p>These Terms and Conditions shall be governed by the laws of Egypt.</p>

                    <h3 className="font-semibold text-lg">11. Changes to Terms</h3>
                    <p>We may modify these terms at any time. Continued use after changes indicates acceptance.</p>

                    <h3 className="font-semibold text-lg">12. Contact Information</h3>
                    <p>For questions, contact us at <a href="mailto:info@boslalearning.com" className="text-blue-900  underline">info@boslalearning.com</a>.</p>

                </div>
            </Modal>

        </>
    )
}
