import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import './NavBar.css';
import logo from '../../images/logo.svg'
import { Dropdown, Space } from 'antd';
import avatar from '../../images/avatar.jpeg'
import axios from 'axios';

const Navbar = ({ baseUrl }) => {
    const [userPhoto, setuserPhoto] = useState();
    const [userName, setuserName] = useState();
    const userToken = localStorage.getItem('userToken')
    const headers = {
        'Authorization': `Bearer ${localStorage.getItem("userToken")}`
    }
    const GetMyProfile = async () => {
        await axios.get(`${baseUrl}/api/Provider/GetMyProfile`, { headers }).then((res) => {
            setuserPhoto(res.data.imageId)
            setuserName(res.data.username)
        }).catch((err) => {

        })
            .finally(() => {
            })
    }
    function logOut() {
        navigate('/login')
        localStorage.removeItem('userToken')
    }

    useEffect(() => {
        if (localStorage.getItem('userToken')) {
            GetMyProfile()
        }
    }, []);
    const items = [
        {
            label: <Link className='no-underline' to="/profileUser">My Profile</Link>,
            key: '0',
        },
        // {
        //     label: <Link className='no-underline' to="/wishList">Wish List</Link>,
        //     key: '1',
        // },
        // {
        //     label: <a className='no-underline' href="https://www.aliyun.com">Settings</a>,
        //     key: '2',
        // },
        {
            type: 'divider',
        },
        {
            label: <p className='no-underline text-red-800' onClick={logOut}><i className='fa fa-sign-out'></i> Log Out</p>,
            key: '3',
        },
    ];
    const navigate = useNavigate();
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

    const toggleMobileMenu = () => {
        setIsMobileMenuOpen(!isMobileMenuOpen);
    };

    return (
        <nav className={` hovr bg-[#3c4f5d]  sm:px-4   rounded px-3  w-full overflow-hidden z-50 top-0 ${isMobileMenuOpen ? '' : 'max-h-16 '} transition-all duration-100`}>
            <div className="lg:container flex flex-wrap justify-between items-start py-2 mx-auto ">
                <span className="w-40   ">
                    <img src={logo} alt="" className='md:w-auto  drop-shadow-lg  sm:mx-0 mx-auto object-contain cursor-pointer' onClick={() => { navigate('/home') }} />
                </span>
                <div className="flex items-center">
                    <button
                        id="menu-toggle"
                        type="button"
                        className="inline-flex items-center p-2 ml-3 text-sm text-gray-500 rounded-lg hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600 lg:hidden"
                        onClick={toggleMobileMenu} // Toggle mobile menu on click
                    >
                        <span className="sr-only">Open main menu</span>
                        <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M4 6h16M4 12h16m-7 6h7"
                            />
                        </svg>
                    </button>
                </div>
                <div className={`w-full md:block md:w-auto lg:mt-0 mt-5`} id="mobile-menu">
                    <ul className="flex items-start nav-links flex-col mt-4 md:flex-row md:space-x-5 md:mt-0 md:text-sm md:font-medium text-lg">

                        {userToken === null ? '' :
                            <li>
                                <p
                                    className="flex items-center gap-2 lg:hidden   py-1 pr-5 pl-3 cursor-pointer text-lg no-underline text-[#758694] hover:text-[#405d72]"
                                    onClick={() => { navigate('/profileUser') }}
                                >
                                    <i className='fa fa-user-circle fa-lg'></i> <span>{userName}</span>
                                </p>
                            </li>
                        }
                        <li>
                            <a
                                className="block py-2 pr-4 pl-3 cursor-pointer text-lg no-underline text-[#758694] hover:text-[#405d72]"
                                href={`#our-courses`}
                                onClick={() => { navigate('/home') }}                            >
                                Our Courses
                            </a>
                        </li>
                        <li>
                            <a
                                className="block py-2 pr-4 pl-3 cursor-pointer text-lg no-underline text-[#758694] hover:text-[#405d72]"
                                href={`#who-we-are`}
                                onClick={() => { navigate('/home') }}
                            >
                                About Us
                            </a>
                        </li>
                        <li>
                            <a
                                className="block py-2 pr-4 pl-3 cursor-pointer text-lg no-underline text-[#758694] hover:text-[#405d72]"
                                onClick={() => { navigate('/home') }}
                                href="#testimonials"
                            >
                                Testimonials
                            </a>
                        </li>
                        <li>
                            <p
                                className="block py-2 pr-4 pl-3 cursor-pointer text-lg"
                                onClick={() => { navigate('/getintouch') }}
                            >
                                Get In Touch
                            </p>
                        </li>

                        {userToken === null ? <li>
                            <p
                                className="block py-2 pr-4 pl-3 cursor-pointer text-lg"
                                onClick={() => { navigate('/login') }} >
                                <i className='fa fa-sign-in me-2'></i> Log in
                            </p>
                        </li> : <>
                            <p
                                className=" pr-4 pl-3 cursor-pointer text-lg lg:block hidden"
                            >
                                <Dropdown
                                    menu={{
                                        items,
                                    }}
                                    trigger={['click']}

                                >
                                    <img src={userPhoto === undefined || userPhoto === null ? 'https://th.bing.com/th/id/OIP.3DtAyOwRrErzTWGNKjf3RwHaHa?rs=1&pid=ImgDetMain' : `${baseUrl}/api/Storage/GetImageById?id=${userPhoto}`} alt='Profile' className="size-12  border-white rounded-full object-cover" />



                                </Dropdown>
                            </p>
                            <p
                                className="lg:hidden text-red-500 text-opacity-60 hover:text-opacity-100 block py-2 pr-4 pl-3 cursor-pointer text-lg"
                                onClick={() => { logOut() }} >
                                <i className='fa fa-sign-out me-2'></i>Log out
                            </p>
                        </>}


                    </ul>
                </div>
            </div>
        </nav>
    );
};

export default Navbar;
